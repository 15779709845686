import Swiper, {Navigation, Pagination, Autoplay, SwiperOptions} from "../../../../../jp-assets/node_modules/swiper";
import {Controller} from "../../../../../jp-assets/node_modules/stimulus"

export default class JobLinksController extends Controller {
    connect() {
        Swiper.use([Autoplay, Navigation, Pagination]);

        const options: SwiperOptions = {
            loopedSlides: 15,
            slidesPerView: "auto",
            slidesPerGroup: 1,
            centerInsufficientSlides: true,
            breakpoints: {
                // when window width is >= 1024px
                1024: {
                    slidesPerGroup: 3
                }
            },
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination-bullets",
            }
        }

        const swiper = new Swiper(".swiper", options)
        window['swiper'] = swiper;
        swiper.init()
    }
}
